export default {
    colors: {
      text: '#2d3748',
      background: '#fff',
      primary: '#fff',
      modes: {
        light: {
          text: '#2d3748',
          background: '#fff',
          primary: '#fff',
        },
        dark: {
          text: '#edf2f7',
          background: '#1d4354',
          primary: '#1a202c',
        },
      }
    }
  }